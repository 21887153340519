import React, { Component } from 'react';

class Section extends Component {
  render() {
    return (
      <div className="quotes-quaternary" style={{ backgroundColor: this.props.settings.bgColor }}>
        <div className="quotes-quaternary__picture">
          <img className="quotes-quaternary__picture-content" src={this.props.settings.image} alt="" />
        </div>
        <div className="quotes-quaternary__divider">
          &nbsp;
        </div>
        <div className="quotes-quaternary__main">
          <div className="quotes-quaternary__content">
            "{this.props.settingsGeneral.quoteContent}"
          </div>
          <div className="quotes-quaternary__author">
            — {this.props.settingsGeneral.quoteAuthor}
          </div>
        </div>
      </div>
    );
  }
}

export default Section;
