import React, { Component } from "react";
import "./LineItem.scss";
import { ReactComponent as Remove } from "../../public/x.svg";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.changeQuantity = this.changeQuantity.bind(this);
  }

  changeQuantity(quantity, lineItemId) {
    this.props.updateQuantityInCart(lineItemId, quantity);
  }

  render() {
    const variantImage = this.props.line_item.variant.image?.src
      ? this.props.line_item.variant.image?.src
      : null;
    return (
      <li className="line-item">
        <div className="line-item__meta">
          <button
            className="line-item__remove"
            onClick={() =>
              this.props.removeLineItemInCart(this.props.line_item.id)
            }
          >
            <Remove />
          </button>
          <div className="line-item__img">
            <img
              src={variantImage}
              alt={`${this.props.line_item.title} product shot`}
            />
          </div>
          <div className="line-item__content">
            <div className="line-item__content-row">
              <div className="line-item__title">
                {this.props.line_item.title}
              </div>
              <div className="line-item__variant-title">
                {this.props.line_item.variant.title}
              </div>
              <div className="line-item__price">
                ${" "}
                {(
                  this.props.line_item.quantity *
                  this.props.line_item.variant.price.amount
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="line-item__quantity-container">
          <select
            className="line-item__option"
            value={this.props.line_item.quantity}
            onChange={(event) =>
              this.changeQuantity(event.target.value, this.props.line_item.id)
            }
          >
            <option value="0" key="0">
              0
            </option>
            <option value="1" key="1">
              1
            </option>
            <option value="2" key="2">
              2
            </option>
            <option value="3" key="3">
              3
            </option>
            <option value="4" key="4">
              4
            </option>
            <option value="5" key="5">
              5
            </option>
          </select>
        </div>
      </li>
    );
  }
}

export default LineItem;
