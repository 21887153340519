import Scrollchor from "react-scrollchor";

const AppHeader = ({ settingsGeneral, settings }) => {
  const titleInfluenceMerged = settingsGeneral.titleInfluence.replace(
    / /g,
    "\u00a0"
  );
  const titleBrandMerged = settingsGeneral.titleBrand.replace(/ /g, "\u00a0");

  return (
    <div className="header-primary">
      <picture className="header-primary__background">
        <source media="(min-width: 768px)" srcSet={settings.imageLarge} />
        <source media="(min-width: 480px)" srcSet={settings.imageMedium} />
        <img src={settings.image} alt="" />
      </picture>
      <div className="header-primary__header-container">
        <div className="header-primary__title">
          <div className="header-primary__title-main">
            <h1 className="header-primary__title-content">
              <div
                className="header-primary__title-text"
                style={{ backgroundColor: settings.titleBgColor }}
              >
                <span className="header-primary__title-text-block">
                  {titleInfluenceMerged}
                </span>{" "}
                x&nbsp;
                <span className="header-primary__title-text-block">
                  {titleBrandMerged}
                </span>
              </div>
            </h1>
          </div>
          <br />
          <Scrollchor
            to="show-below"
            className="header-primary__title-cta"
            style={{ backgroundColor: settings.shopCtaColor }}
          >
            Shop the collection
          </Scrollchor>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;

