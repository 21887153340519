import { renderDiscount } from "../../utils";

const Section = ({ settingsGeneral }) => {
  return (
    <div id="shop-below-secondary" className="shop-below-secondary">
      <div className="shop-below-secondary__title">
        <div className="shop-below-secondary__title-content">
          Shop below and get{" "}
          {renderDiscount(settingsGeneral.discount)}
        </div>
      </div>
    </div>
  );
};

export default Section;
