import React, { Component } from 'react';

class QuotesSecondary extends Component {
  render() {
    return (
      <div className="quotes-secondary" style={{ backgroundColor: this.props.settings.bgColor }}>
        <div className="quotes-secondary__picture">
          <img className="quotes-secondary__picture-content" src={this.props.settings.image} alt="" />
        </div>
        <div className="quotes-secondary__main">
          <div className="quotes-secondary__content">
            "{this.props.settingsGeneral.quoteContent}"
          </div>
          <div className="quotes-secondary__author">
            — {this.props.settingsGeneral.quoteAuthor}
          </div>
        </div>
      </div>
    );
  }
}

export default QuotesSecondary;
