import { useState, useEffect } from "react";

import Cart from "../components/Cart/Cart";
import Components from "../components";

const Home = ({
  checkout,
  microsite,
  microsite: { settingsGeneral },
  products,
  cartQty,
  updateQuantityInCart,
  addLineItemToCart,
  removeLineItemInCart,
  openCheckout,
}) => {
  const [isCartOpen, setCartOpen] = useState(false);
  const closeCart = () => setCartOpen(false);

  useEffect(() => {
    //When cart is open, add class with overflow to body
    if (isCartOpen) {
      document.body.classList.add("cart-open");
    } else {
      document.body.classList.remove("cart-open");
    }
  }, [isCartOpen]);
  return (
    <div className="MainApp">
      {microsite.sections.map((block, index) =>
        Components({
          block,
          index,
          openCart: () => setCartOpen(true),
          cartQty: cartQty,
          products: products,
          addVariantToCart: addLineItemToCart,
          settingsGeneral,
        })
      )}
      <Cart
        checkout={checkout}
        isCartOpen={isCartOpen}
        handleCartClose={closeCart}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
        openCheckout={openCheckout}
        settingsGeneral={settingsGeneral}
      />
    </div>
  );
};

export default Home;
