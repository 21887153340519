import "./styles/main.scss";
import queryString from "query-string";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import * as exampleOptions from "./helpers/exampleOptions";
import Home from "./components/Home";
import Error from "./components/Error";
import Spinner from "./components/Spinner/Spinner";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import TokenExpired from "./components/TokenExpired/TokenExpired";
import previewProducts from "./helpers/previewProducts.json";
import { transformMicrosite, flattenCollectionEntries } from "./utils";
import {
  graphqlClient,
  fetchInitial,
  fetchCampaign,
  fetchInfluencerCampaign,
} from "./api/preview";

const App = () => {
  const [isLoading, setLoading] = useState(true);

  const [is404, set404] = useState(false);

  const [tokenHasExpired, setTokenHasExpired] = useState(false);

  //Microsite that will configure this template
  const [microsite, setMicrosite] = useState();

  const [products, setProducts] = useState();

  const [cartQty, setCartQty] = useState(0);

  const completeLoading = () => setLoading(false);

  const [checkout, setCheckout] = useState({
    lineItems: [],
    totalPrice: { amount: 0, currencyCode: "USD" },
    subtotalPrice: { amount: 0, currencyCode: "USD" },
  });

  const checkTokenExpired = (e) => {
    if (!e.hasOwnProperty("response")) {
      console.error(e);
      throw e;
    }
    const { errorType, message } = e.response.errors[0];
    if (
      errorType === "UnauthorizedException" &&
      message === "Token has expired."
    ) {
      setTokenHasExpired(true);
      return;
    }
    throw e;
  };

  const openCheckout = (cb) => {
    //window.open(checkout.webUrl);
    alert("No checkout available in preview mode.");
    cb();
  };

  const addLineItemToCart = (variant, quantity) => {
    setCartQty(cartQty + parseInt(quantity, 10));
    let variant_;
    let title;
    for (let lineItem of checkout.lineItems) {
      if (lineItem.id === variant) {
        setCheckout({
          ...checkout,
          lineItems: checkout.lineItems.map((lineItem) => {
            if (lineItem.id === variant) {
              return {
                ...lineItem,
                quantity: lineItem.quantity + parseInt(quantity, 10),
              };
            }
            return lineItem;
          }),
        });
        return;
      }
    }
    for (let product of products) {
      for (let v of product.variants) {
        if (v.id === variant) {
          variant_ = v;
          title = product.name;
          break;
        }
      }
      if (variant_) {
        break;
      }
    }

    const result = {
      id: variant_.id,
      title: variant_.selectedOptions
        .map(({ selection }) => selection)
        .join(" , "),
      price: {
        amount: variant_.price.amount,
        currencyCode: variant_.price.currency,
      },
      image: variant_.image,
    };
    setCheckout({
      ...checkout,
      lineItems: [
        ...checkout.lineItems,
        {
          id: variant_.id,
          title,
          variant: result,
          quantity: parseInt(quantity, 10),
        },
      ],
    });
  };

  const removeLineItemInCart = (lineItemId) => {
    setCheckout({
      ...checkout,
      lineItems: checkout.lineItems.filter(({ variant, quantity }) => {
        if (variant.id !== lineItemId) {
          return true;
        } else {
          setCartQty(cartQty - quantity);
          return false;
        }
      }),
    });
  };

  useEffect(() => {
    document.title = "Microsite Preview";
    const updateCampaign = (campaign, modified) => {
      if (campaign.modified !== modified) {
        setMicrosite(
          transformMicrosite(
            campaign.microsite,
            campaign.discount,
            campaign.brand,
            campaign.influencer
          )
        );
        completeLoading();
      }
    };
    const params = queryString.parse(window.location.search);
    let interval;
    if (
      (params.brandId || params.influencerId) &&
      params.campaignId &&
      params.accessToken
    ) {
      if (params.brandId && params.influencerId) {
        throw new Error("Both brandId and influencerId cannot be present.");
      }

      if (params.template) {
        throw new Error(
          "Template cannot be present when any other arguments other than accessToken are."
        );
      }

      (async () => {
        const cli = graphqlClient(params.accessToken);
        try {
          const { campaign, products } = await fetchInitial(
            cli,
            params.campaignId,
            params.brandId,
            params.influencerId
          );

          setProducts(flattenCollectionEntries(products));
          updateCampaign(campaign);
          let modified = campaign.modified;
          completeLoading();

          //This seems spammy but will likely not be used by a whole lot
          //of concurrent users in the beginning.
          interval = setInterval(() => {
            if (params.brandId)
              fetchCampaign(cli, params.campaignId, campaign.brand.id).then(
                (campaign) => {
                  updateCampaign(campaign, modified);
                  modified = campaign.modified;
                }
              );
            else {
              fetchInfluencerCampaign(
                cli,
                params.campaignId,
                campaign.influencer.id
              )
                .then((campaign) => {
                  updateCampaign(campaign, modified);
                  modified = campaign.modified;
                })
                .catch(checkTokenExpired);
            }
          }, 1500);
        } catch (e) {
          checkTokenExpired(e);
        }
      })();
    } else if (params.template) {
      setProducts(previewProducts);
      switch (params.template) {
        case "ONE":
          setMicrosite(exampleOptions.primary);
          break;
        case "TWO":
          setMicrosite(exampleOptions.secondary);
          break;
        case "THREE":
          setMicrosite(exampleOptions.tertiary);
          break;
        case "FOUR":
          setMicrosite(exampleOptions.quaternary);
          break;
        default:
          set404(true);
          return;
      }
      completeLoading();
    } else {
      set404(true);
    }
    return clearInterval(interval);
  }, [setMicrosite, setLoading, setProducts]);

  if (is404) {
    return <PageNotFound />;
  }

  if (tokenHasExpired) {
    return <TokenExpired />;
  }

  if (!microsite) {
    return <Spinner isLoading={isLoading} />;
  }

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Home
        checkout={checkout}
        microsite={microsite}
        products={products}
        cartQty={cartQty}
        addLineItemToCart={addLineItemToCart}
        removeLineItemInCart={removeLineItemInCart}
        openCheckout={openCheckout}
      />
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
