import React, { Component } from 'react';
import { ReactComponent as QuoteIconLeft } from '../../public/quote-left.svg'
import { ReactComponent as QuoteIconRight } from '../../public/quote-right.svg'

class Quotes extends Component {
  render() {
    return (
      <div className="quotes">
        <div className="quotes__picture">
          <img className="quotes__picture-content" src={this.props.settings.image} alt="" />
        </div>
        <div className="quotes__main">
          <div className="quotes__content">
            <QuoteIconLeft className="quotes__icon-left" fill={ this.props.settings.quoteIconColor } />
            {this.props.settingsGeneral.quoteContent}
            <QuoteIconRight className="quotes__icon-right" fill={ this.props.settings.quoteIconColor } />
          </div>
          <div className="quotes__author">
            — {this.props.settingsGeneral.quoteAuthor}
          </div>
        </div>
      </div>
    );
  }
}

export default Quotes;
