import { gql, GraphQLClient } from "graphql-request";

/* Influence Sciences AppSync */
export const ENDPOINT_URL = process.env.ENDPOINT_URL;

export const fetchInitial = async (
  client,
  campaignId,
  brandId = null,
  influencerId = null
) => {
  if (brandId !== null) {
    const campaign = await fetchCampaign(client, campaignId, brandId);
    const products = await fetchCampaignProducts(client, campaignId, brandId);
    return { campaign, products };
  } else if (influencerId !== null) {
    const campaign = await fetchInfluencerCampaign(
      client,
      campaignId,
      influencerId
    );
    const products = await fetchInfluencerCampaignProducts(
      client,
      campaignId,
      influencerId
    );
    return { campaign, products };
  }
};

export const fetchCampaign = async (client, campaignId, brandId) => {
  const { campaign } = await client.request(brandCampaignQuery, {
    brandId,
    campaignId,
  });
  return campaign;
};

export const fetchCampaignProducts = async (client, campaignId, brandId) => {
  const { campaign } = await client.request(brandCampaignProductsQuery, {
    brandId,
    campaignId,
  });
  return campaign.products;
};

export const fetchInfluencerCampaign = async (
  client,
  campaignId,
  influencerId
) => {
  const { influencerCampaign } = await client.request(influencerCampaignQuery, {
    influencerId,
    campaignId,
  });
  return influencerCampaign;
};

export const fetchInfluencerCampaignProducts = async (
  client,
  campaignId,
  influencerId
) => {
  const { influencerCampaign } = await client.request(
    influencerCampaignProductsQuery,
    {
      influencerId,
      campaignId,
    }
  );
  return influencerCampaign.products;
};

export const graphqlClient = (accessToken) => {
  return new GraphQLClient(ENDPOINT_URL, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const brandQuery = gql`
  query Brand($brandId: ID!) {
    brand(id: $brandId) {
      name
      website
      imageUrl
    }
  }
`;

export const influencerQuery = gql`
  query Influencer($influencerId: ID!) {
    influencer(id: $influencerId) {
      name
      website
      imageUrl
    }
  }
`;

export const brandCampaignQuery = gql`
  query CampaignQuery($brandId: ID!, $campaignId: ID!) {
    campaign(brandId: $brandId, id: $campaignId) {
      modified
      brand {
        id
        name
        website
        imageUrl
      }
      influencer {
        id
        name
        website
        imageUrl
      }
      discount {
        ... on PercentageDiscount {
          percentage
        }
        ... on FlatDiscount {
          minimum {
            amount
            currency
          }
          amount {
            amount
            currency
          }
        }
      }
      microsite {
        template
        productBuyBgColor
        productBuyTextColor
        influencerDisplayName
        influencerTitleName
        mainHeader {
          brandImage
          influencerImage
          dark
        }
        appHeader {
          titleBgColor
          shopCtaColor
          image
          imageMedium
          imageLarge
          image1
          image2
          image3
        }
        products {
          direction
          total
        }
        influencerQuote {
          image
          bgColor
          quoteIconColor
          quoteContent
          quoteAuthor
        }
        hero {
          image
          imageMedium
          imageLarge
        }
        shopBelow {
          bgColor
        }
        footer {
          bgColor
          logo
        }
      }
      discount {
        ... on PercentageDiscount {
          percentage
        }
        ... on FlatDiscount {
          amount {
            amount
            currency
          }
          minimum {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const influencerCampaignQuery = gql`
  query CampaignQuery($influencerId: ID!, $campaignId: ID!) {
    influencerCampaign(influencerId: $influencerId, id: $campaignId) {
      modified
      brand {
        id
        name
        website
        imageUrl
      }
      influencer {
        id
        name
        website
        imageUrl
      }
      microsite {
        template
        productBuyBgColor
        productBuyTextColor
        influencerDisplayName
        influencerTitleName
        mainHeader {
          brandImage
          influencerImage
          dark
        }
        appHeader {
          titleBgColor
          shopCtaColor
          image
          imageMedium
          imageLarge
          image1
          image2
          image3
        }
        products {
          direction
          total
        }
        influencerQuote {
          image
          bgColor
          quoteIconColor
          quoteContent
          quoteAuthor
        }
        hero {
          image
          imageMedium
          imageLarge
        }
        shopBelow {
          bgColor
        }
        footer {
          bgColor
          logo
        }
      }
      discount {
        ... on PercentageDiscount {
          percentage
        }
        ... on FlatDiscount {
          amount {
            amount
            currency
          }
          minimum {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const brandCampaignProductsQuery = gql`
  query BrandCampaignProducts($brandId: ID!, $campaignId: ID!) {
    campaign(brandId: $brandId, id: $campaignId) {
      products {
        products {
          product {
            id
            name
            images {
              images {
                src
              }
            }
            estimatedQty
            priceRange {
              min {
                amount
                currency
              }
              max {
                amount
                currency
              }
            }
            options {
              key
              values
            }
            variants {
              variants {
                id
                sku
                price {
                  amount
                  currency
                }
                image {
                  src
                }
                selectedOptions {
                  key
                  selection
                }
              }
            }
          }
          quantity
        }
      }
    }
  }
`;

export const influencerCampaignProductsQuery = gql`
  query InfluencerCampaignProducts($influencerId: ID!, $campaignId: ID!) {
    influencerCampaign(influencerId: $influencerId, id: $campaignId) {
      id
      products {
        products {
          product {
            id
            name
            images {
              images {
                src
              }
            }
            estimatedQty
            priceRange {
              min {
                amount
                currency
              }
              max {
                amount
                currency
              }
            }
            options {
              key
              values
            }
            variants {
              variants {
                id
                sku
                price {
                  amount
                  currency
                }
                image {
                  src
                }
                selectedOptions {
                  key
                  selection
                }
              }
            }
          }
          quantity
        }
      }
    }
  }
`;
