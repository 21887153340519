import "./TokenExpired.scss";

const TokenExpired = () => {
  return (
    <div className="token-expired__fullyCentered">
      <h1>Token has expired.</h1>
    </div>
  );
}

export default TokenExpired;
