import React, { Component } from 'react';
import Headroom from 'react-headroom'
import { ReactComponent as CartLogo } from '../../public/shopping_cart.svg'

class MainHeader extends Component {
  constructor(props) {
    super(props)
    this.listener = null;
    this.state = {
      scrolled: false,
    }
  }

  componentDidMount() {
    this.listener = document.addEventListener('scroll', e => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1) {
        this.setState({ scrolled: true })
      } else {
        this.setState({ scrolled: false })
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener('scroll', this.listener);
  }

  render() {
    return (
      <Headroom
        className={`
          ${this.props.settings.dark ? 'headroom-wrapper--dark' : ''}
          ${this.state.scrolled ? 'headroom--after-start' : 'headroom--start'}
        `}
        disableInlineStyles
      >
        <div className="header-primary__top">
          <div className="header-primary__top-blank" />
          <div className="header-primary__profile">
            <div className="header-primary__profile-item">
              <div className="header-primary__profile-pic">
                <a href={this.props.settingsGeneral.brandLink}>
                  <img src={this.props.settings.brandImage} alt="" />
                </a>
              </div>
              <a href={this.props.settingsGeneral.brandLink} className="header-primary__profile-link">
                {this.props.settingsGeneral.brand}
              </a>
            </div>
            <div className="header-primary__profile-item">
              <div className="header-primary__profile-pic">
                <a href={this.props.settingsGeneral.influencerLink}>
                  <img src={this.props.settings.influencerImage} alt="" />
                </a>
              </div>
              <a href={this.props.settingsGeneral.influencerLink} className="header-primary__profile-link">
                {this.props.settingsGeneral.influencer}
              </a>
            </div>
          </div>
          <div className="header-primary__view-cart-wrapper">
            <div className="header-primary__view-cart" onClick={()=> this.props.openCart()}>
              <div className="header-primary__view-cart-main">
                <div className="header-primary__view-cart-main-count">
                  {this.props.cartQty}
                </div>
                <CartLogo className="header-primary__cart-logo"/>
              </div>

              <div className="header-primary__view-cart-text">Cart</div>
            </div>
          </div>
        </div>
      </Headroom>
    )
  }
}

export default MainHeader;