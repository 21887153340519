import "./PageNotFound.scss";

const PageNotFound = () => {
  return (
    <div className="page-not-found__fullyCentered">
      <h1>Page not found.</h1>
    </div>
  );
};

export default PageNotFound;
