import { renderDiscount } from "../../utils";

const ShopBelow = ({ settings, settingsGeneral }) => {
  return (
    <div id="show-below" className="shop-below">
      <div
        className="shop-below__title"
        style={{ backgroundColor: settings.bgColor }}
      >
        <div className="shop-below__title-content">
          Shop below and get{" "}
          {renderDiscount(settingsGeneral.discount)}
        </div>
      </div>
    </div>
  );
};

export default ShopBelow;
