import Scrollchor from "react-scrollchor";
import { renderDiscount } from "../../utils";

const Section = ({ settings, settingsGeneral }) => {
  return (
    <div className="header-tertiary">
      <div className="header-tertiary__main">
        <div className="header-tertiary__image">
          <img src={settings.image} alt="Header" />
        </div>
        <div className="header-tertiary__title">
          <div
            className="
                header-tertiary__title-bar
                header-tertiary__title-bar--top
              "
            style={{ backgroundColor: settings.shopCtaColor }}
          ></div>
          <div className="header-tertiary__title-bar--bottom"></div>
          <div>
            <div className="header-tertiary__title-main">
              <h2 className="header-tertiary__title-content">
                {settingsGeneral.titleInfluence}&nbsp;&
              </h2>
              <h2 className="header-tertiary__title-content">
                {settingsGeneral.titleBrand}
              </h2>
            </div>
            <div className="header-tertiary__title-sub">
              Shop below and get{" "}
              {renderDiscount(settingsGeneral.discount)}
            </div>
            <Scrollchor
              to="product-section"
              animate={{ offset: -150 }}
              className="header-tertiary__title-cta"
              style={{ backgroundColor: settings.shopCtaColor }}
            >
              Shop the collection
            </Scrollchor>
          </div>
          <div
            className="
                header-tertiary__title-bar
                header-tertiary__title-bar--bottom
              "
            style={{ backgroundColor: settings.shopCtaColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Section;

