import React, { Component } from 'react';

class Hero extends Component {
  render() {
    return (
      <div className={`
        hero
        ${this.props.settings.spaceBelow ? "hero--space-below" : ""}
      `}>
        <div className="hero__picture">
          <picture className="hero__picture-img">
            <source media="(min-width: 768px)" srcSet={this.props.settings.imageLarge} />
            <source media="(min-width: 480px)" srcSet={this.props.settings.imageMedium} />
            <img src={this.props.settings.image} alt="" />
          </picture>
        </div>
      </div>
    );
  }
}

export default Hero;
