import React, { Component } from 'react';
import { ReactComponent as Logo } from '../../public/fomo_promo_logo.svg'

class Footer extends Component {
  render() {
    return (
      <div className="footer" style={{ backgroundColor: this.props.settings.bgColor }}>
        <div className="footer__column footer__column--first">
          <div className="footer__brand">
            <div className="footer__powered-by">POWERED BY</div>
            <div className="footer__logo">
              <Logo/>
            </div>
          </div>
        </div>
        <div className="footer__column footer__column--second">
          <ul className="footer__list">
            {this.props.settingsGeneral.footerLinks.map((item, idx) => (
              <li className="footer__list-item" key={idx}>
                <a className="footer__link" href={item.link}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__column footer__column--third">
          <ul className="footer__list">
            {this.props.settingsGeneral.footerLinksSecondary.map((item, idx) => (
              <li className="footer__list-item" key={idx}>
                <a className="footer__link" href={item.link}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
