import React, { Component } from 'react';
import Scrollchor from 'react-scrollchor';

class Section extends Component {
  render() {
    return (
      <div className="header-quaternary">
        <div className="header-quaternary__title">
          <div>
            <div className="header-quaternary__title-main">
              <h2 className="header-quaternary__title-content">
                {this.props.settingsGeneral.titleInfluence}&nbsp;&nbsp;&nbsp;&&nbsp;&nbsp;&nbsp;{this.props.settingsGeneral.titleBrand}
              </h2>
            </div>
            <br />
            <Scrollchor
              to="shop-below-secondary"
              className="header-quaternary__title-cta"
              style={{ backgroundColor: this.props.settings.shopCtaColor }}
            >
              Shop the collection
            </Scrollchor>

          </div>
        </div>
        <div className="header-quaternary__photos">
          <div className="header-quaternary__photo">
            <img
              className="header-quaternary__photo-img"
              src={this.props.settings.image1}
              alt="Header"
            />
          </div>
          <div className="header-quaternary__photo header-quaternary__photo--middle">
            <img
              className="header-quaternary__photo-img"
              src={this.props.settings.image2}
              alt="Header"
            />
          </div>
          <div className="header-quaternary__photo">
            <img
              className="header-quaternary__photo-img"
              src={this.props.settings.image3}
              alt="Header"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Section;