import { useState, useEffect } from "react";
import "./Spinner.scss";

const Spinner = ({ isLoading }) => {
  const [started, setStarted] = useState(false);
  let cls = "loader";
  if (started) {
    cls = "loader isLoading";
  }
  useEffect(() => {
    const startLoader = setTimeout(() => {
      setStarted(true);
    }, 1000);
    return () => clearTimeout(startLoader);
  }, []);

  return (
      <div className={isLoading ? "spinner loading" : "spinner stopped"}>
        <div className="cssload-dots">
          <div className="cssload-dot"></div>
          <div className="cssload-dot"></div>
          <div className="cssload-dot"></div>
          <div className="cssload-dot"></div>
          <div className="cssload-dot"></div>
        </div>

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                result="blur"
                stdDeviation="12"
              ></feGaussianBlur>
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7"
                result="goo"
              ></feColorMatrix>
              <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
            </filter>
          </defs>
        </svg>
        <div className={cls}></div>
      </div>
  );
};

export default Spinner;
