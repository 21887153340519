import React, {Component} from 'react';

class VariantSelector extends Component {
  render() {
    return (
      <select
        className="product__option"
        name={this.props.option.key}
        key={this.props.option.key}
        value={this.props.value}
        onChange={this.props.handleOptionChange}
      >
        <option value="">{`${this.props.option.key}`}</option>
        {this.props.option.values.map((value) => {
          return (
            <option value={value} key={`${this.props.option.key}-${value}`}>{`${value}`}</option>
          )
        })}
      </select>
    );
  }
}

export default VariantSelector;
