import React from 'react';
import Products from './components/Product/Products';
import AppHeader from './components/AppHeader/AppHeader'
import AppHeaderSecondary from './components/AppHeaderSecondary/AppHeaderSecondary'
import AppHeaderTertiary from './components/AppHeaderTertiary/AppHeaderTertiary'
import AppHeaderQuaternary from './components/AppHeaderQuaternary/AppHeaderQuaternary'
import Quotes from './components/Quotes/Quotes'
import QuotesSecondary from './components/QuotesSecondary/QuotesSecondary'
import QuotesTertiary from './components/QuotesTertiary/QuotesTertiary'
import QuotesQuaternary from './components/QuotesQuaternary/QuotesQuaternary'
import Hero from './components/Hero/Hero'
import MainHeader from './components/MainHeader/MainHeader'
import ShopBelow from './components/ShopBelow/ShopBelow'
import ShopBelowSecondary from './components/ShopBelowSecondary/ShopBelowSecondary'
import Footer from './components/Footer/Footer'

const Components = {
  'main-header': MainHeader,
  'products': Products,
  'app-header': AppHeader,
  'app-header-secondary': AppHeaderSecondary,
  'app-header-tertiary': AppHeaderTertiary,
  'app-header-quaternary': AppHeaderQuaternary,
  'quote': Quotes,
  'quote-secondary': QuotesSecondary,
  'quote-tertiary': QuotesTertiary,
  'quote-quaternary': QuotesQuaternary,
  'hero': Hero,
  'shop-below': ShopBelow,
  'shop-below-secondary': ShopBelowSecondary,
  'footer': Footer,
}

const sections = ({
  block,
  index,
  settingsGeneral,
  openCart,
  cartQty,
  products,
  addVariantToCart,
}) => {
  if (typeof Components[block.name] !== 'undefined') {
    if (block.name === 'main-header') {
      return React.createElement(Components[block.name], {
        key: block.name + index,
        settings: block.settings,
        settingsGeneral,
        openCart,
        cartQty,
      })
    }

    if (
      block.name === 'app-header'
      || block.name === 'app-header-secondary'
      || block.name === 'app-header-tertiary'
      || block.name === 'app-header-quaternary'
    ) {
      return React.createElement(Components[block.name], {
        key: block.name + index,
        settings: block.settings,
        settingsGeneral,
        openCart,
        cartQty,
      })
    }

    if (block.name === 'products') {
      return React.createElement(Components[block.name], {
        key: block.name + index,
        settings: block.settings,
        settingsGeneral,
        products,
        addVariantToCart,
      })
    }

    return React.createElement(Components[block.name], {
      key: block.name + index,
      settings: block.settings,
      settingsGeneral,
    })
  }

  return React.createElement(
    () => <div>The component {block.name} has not been created yet.</div>,
    { key: block.name }
  )
}

export default sections