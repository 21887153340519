import Product from "./Product";

const Products = ({
  settings,
  settingsGeneral,
  addVariantToCart,
  products,
  checkout,
}) => {
  //const totalProducts = this.props.settings.total
  //const listedProducts = this.props.products.slice(0, totalProducts)

  const listedProducts = products.map((product) => {
    return (
      <Product
        addVariantToCart={addVariantToCart}
        checkout={checkout}
        key={product.id.toString()}
        product={product}
        settings={settings}
        settingsGeneral={settingsGeneral}
      />
    );
  });

  return (
    <div id="product-section" className="product-section">
      <div className="product-wrapper">{listedProducts}</div>
    </div>
  );
};

export default Products;
