import { useState } from "react";
import VariantSelector from "./VariantSelector";
import { percentagePrice } from "../../utils";

const Product = ({
  settingsGeneral: {
    productBuyBgColor,
    productBuyTextColor,
    discount: { percentage },
  },
  settings,
  product,
  addVariantToCart,
}) => {
  const initialVariant =
    product.variants.length === 1 ? product.variants[0] : null;

  const opts = initialVariant?.selectedOptions.reduce(
    (acc, { key, selection }) => {
      acc[key] = selection;
      return acc;
    },
    {}
  );

  const [addEnabled, setAddEnabled] = useState(initialVariant != null);
  const [selectedOptions, setSelectedOptions] = useState(opts);
  const [selectedVariantQuantity, setSelectedVariantQuantity] = useState(1);
  const [selectedVariantImage, setSelectedVariantImage] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(initialVariant);
  const [isAdding, setIsAdding] = useState(false);
  const [justAdded, setJustAdded] = useState(false);

  const handleOptionChange = (event) => {
    const newOptions = {
      ...selectedOptions,
      [event.target.name]: event.target.value,
    };
    setSelectedOptions(newOptions);

    // Find all potentially matching variants meaning:
    // all of their options match those selected or the option hasn't been set yet
    const matchingVariants = product.variants.filter((variant) => {
      return variant.selectedOptions.every(({ key, selection }) => {
        return !newOptions[key] || newOptions[key] === selection;
      });
    });
    if (matchingVariants.length === 1) {
      const newVariant = matchingVariants[0];
      setSelectedVariant(newVariant);
      setSelectedVariantImage(newVariant.image?.src);
      setAddEnabled(selectedVariantQuantity > 0);
    } else {
      setSelectedVariant(null);
      setAddEnabled(false);
      if (matchingVariants.length > 1) {
        // Set the selected image to one of those in the matching variants
        setSelectedVariantImage(matchingVariants[0].image?.src);
      }
    }
  };

  const handleQuantityChange = (event) => {
    setSelectedVariantQuantity(event.target.value);
    setAddEnabled(selectedVariant != null && event.target.value > 0);
  };

  const handleAdd = async (variantId, variantQuantity) => {
    setIsAdding(true);
    await addVariantToCart(variantId, variantQuantity, product?.images[0]?.src);
    setIsAdding(false);
    setJustAdded(true);
    setTimeout(() => {
      setJustAdded(false);
      setSelectedVariantQuantity(0);
      setAddEnabled(false);
    }, 500);
  };

  const variantImage =
    selectedVariantImage ||
    product.variants[0].image?.src ||
    product.images[0]?.src;

  const variant = selectedVariant || product.variants[0];
  const variantQuantity = selectedVariantQuantity;

  const quantitySelector = (
    <div className="variantSelector-element">
      <select
        className="product__option"
        name={variantQuantity}
        value={selectedVariantQuantity}
        onChange={handleQuantityChange}
      >
        <option value="0" key="0">
          Qty
        </option>
        <option value="1" key="1">
          1
        </option>
        <option value="2" key="2">
          2
        </option>
        <option value="3" key="3">
          3
        </option>
        <option value="4" key="4">
          4
        </option>
        <option value="5" key="5">
          5
        </option>
      </select>
    </div>
  );

  const variantSelectors =
    product.options[0]?.key === "Title" &&
    product.options[0].values.length === 1 &&
    product.options[0].values[0] === "Default Title" ? (
      <div className="variantSelector-wrapper">{quantitySelector}</div>
    ) : (
      product.options.map((option, inx) => {
        const value = selectedOptions ? selectedOptions[option.key] : "";
        if (inx === 0) {
          return (
            <div className="variantSelector-wrapper" key={option.key}>
              <div className="variantSelector-element">
                <div>
                  <VariantSelector
                    handleOptionChange={handleOptionChange}
                    key={inx}
                    value={value}
                    option={option}
                  />
                </div>
              </div>
              {quantitySelector}
            </div>
          );
        }
        return (
          <div className="variantSelector-wrapper" key={option.key}>
            <div className="variantSelector-element">
              <div>
                <VariantSelector
                  handleOptionChange={handleOptionChange}
                  key={inx}
                  value={value}
                  option={option}
                />
              </div>
            </div>
          </div>
        );
      })
    );

  return (
    <div
      className={`product ${
        settings.direction === "row" ? "product--row" : "product--column"
      }`}
    >
      <div className="product__photo">
        {product.images.length ? (
          <img
            className="product__photo-img"
            src={variantImage}
            alt={`${product.name} product shot`}
          />
        ) : null}
      </div>
      <div className="product__meta">
        <div className="product__meta-inner">
          <div className="product__header">
            <h5 className="product__title">{product.name}</h5>
            {!!percentage && (
              <>
                <span className="product__price">
                  <span className="strikethrough"> ${variant.price.amount}</span>
                  ${percentagePrice(variant.price.amount, percentage)}
                </span>
              </>
              )}
            {!percentage && (
              <span className="product__price">${variant.price.amount}</span>
            )}
          </div>
          <div className="product__variants">{variantSelectors}</div>
          <button
            className={`
                product__buy button
                ${isAdding ? "is-adding" : ""}
              `}
            style={{
              color: productBuyTextColor,
              backgroundColor: productBuyBgColor,
            }}
            onClick={() => handleAdd(variant.id, variantQuantity)}
            disabled={!addEnabled}
          >
            {addEnabled
              ? justAdded
                ? "Added!"
                : "Add to Cart"
              : "Add to Cart"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
