import LineItem from "./LineItem";
import { useState } from "react";
import { ReactComponent as Remove } from "../../public/x.svg";
import { totalDiscount } from "../../utils";

const Cart = ({
  checkout,
  isCartOpen,
  handleCartClose,
  updateQuantityInCart,
  removeLineItemInCart,
  openCheckout,
  settingsGeneral: { discount },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const startedLoading = () => setIsLoading(true);
  const finishedLoading = () => setIsLoading(false);

  const checkoutOpened = async () => {
    startedLoading();
    return openCheckout(() => finishedLoading());
  };

  const lineItems = checkout.lineItems.map((lineItem, inx) => {
    return (
      <LineItem
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
        key={`lineItem-${inx}`}
        line_item={lineItem}
      />
    );
  });

  return (
    <div className={`cart ${isCartOpen ? "cart--open" : ""}`}>
      <header className="cart__header">
        <div className="cart__title">My Cart</div>
        <button onClick={handleCartClose} className="cart__close">
          <Remove />
        </button>
      </header>
      <ul className="cart__line-items">{lineItems}</ul>
      <footer className="cart__footer">
        <div className="cart__info-wrapper">
          <div className="cart__info">
            <div className="cart__info-total cart__info-small">Subtotal</div>
            <div className="cart__info-pricing">
              <span className="cart__pricing">
                $ {checkout.subtotalPrice.amount}
              </span>
            </div>
          </div>
          <div className="cart__info">
            <div className="cart__info-total cart__info-small">Discount</div>
            <div className="cart__info-pricing">
              <span className="cart__pricing">
                $ {totalDiscount(checkout.totalPrice.amount, discount, true)}
              </span>
            </div>
          </div>
          <div className="cart__info">
            <div className="cart__info-total cart__info-small">Total</div>
            <div className="cart__info-pricing">
              <span className="cart__pricing">
                $ {totalDiscount(checkout.totalPrice.amount, discount, false)}
              </span>
            </div>
          </div>
        </div>
        <button
          disabled={isLoading}
          className="cart__checkout button"
          onClick={checkoutOpened}
        >
          Checkout
        </button>
      </footer>
    </div>
  );
};

export default Cart;
