import backdrop from "../public/header_primary.png";
import backdrop_medium from "../public/header_primary_medium.png";
import backdrop_large from "../public/header_primary_large.png";
import backdropSecondary from "../public/header_secondary.png";
import backdropTertiary from "../public/header_tertiary.png";
import appHeaderQuaternary1 from "../public/header_quaternary_1.png";
import appHeaderQuaternary2 from "../public/header_quaternary_2.png";
import appHeaderQuaternary3 from "../public/header_quaternary_3.png";
// import quoteImage from '../public/quote.png';
// import quoteImage2 from '../public/quote2.png';
// import quoteImage3 from '../public/quote3.png';
// import quoteImage4 from '../public/quote4.png';
import heroImage from "../public/hero_bg.png";
import heroImageMedium from "../public/hero_bg_medium.png";
import heroImageLarge from "../public/hero_bg_large.png";
import heroImage2 from "../public/hero_bg2.png";
import heroImage2Medium from "../public/hero_bg2_medium.png";
import heroImage2Large from "../public/hero_bg2_large.png";
import heroImage3 from "../public/hero_bg3.png";
import heroImage3Medium from "../public/hero_bg3_medium.png";
import heroImage3Large from "../public/hero_bg3_large.png";
import heroImage4 from "../public/hero_bg4.png";
import heroImage4Medium from "../public/hero_bg4_medium.png";
import heroImage4Large from "../public/hero_bg4_large.png";
import brand from "../public/brand.png";
import brandSecondary from "../public/brand_secondary.png";
import brandTertiary from "../public/brand_tertiary.png";
import brandQuaternary from "../public/brand_quaternary.png";
import influencer from "../public/influencer.png";
import influencerSecondary from "../public/influencer_secondary.png";
import influencerTertiary from "../public/influencer_tertiary.png";
import influencerQuaternary from "../public/influencer_quaternary.png";
import footerLogo from "../public/fomo_logo.png";

export const primary = {
  store: {
    storefrontAccessToken: "538fe6d6fd9123f61bd9a20481fee32c",
    store: "influencesciences.myshopify.com",
  },
  settingsGeneral: {
    productBuyBgColor: "#984949",
    productBuyTextColor: "#FFFFFF",
    brand: "Velvet & Co",
    brandLink: "https://google.com",
    influencer: "Stefanie Conrad",
    influencerLink: "https://yahoo.com",
    titleInfluence: "Stefanie Conrad",
    titleBrand: "Velvet & Co",
    quoteContent:
      "Velvet & Co has become a staple in my wardrobe. I never feel like I need to sacrifice comfort for style when I wear their clothes and I feel good in them all day long.",
    quoteAuthor: "Stefanie Conrad",
    discount: { percentage: "20" },
    footerLinks: [
      { title: "Stefanie Conrad", link: "http://google.com" },
      { title: "@stefanieconrad", link: "http://yahoo.com" },
      { title: "stefanieconrad.com", link: "http://msn.com" },
    ],
    footerLinksSecondary: [
      { title: "Velvet & Co", link: "http://google.com" },
      { title: "velvetandco.com", link: "http://yahoo.com" },
      { title: "@velvetandco", link: "http://msn.com" },
    ],
  },
  sections: [
    {
      name: "main-header",
      settings: {
        brandImage: brand,
        influencerImage: influencer,
        dark: false,
      },
    },
    {
      name: "app-header",
      settings: {
        titleBgColor: "#984949",
        shopCtaColor: "#984949",
        image: backdrop,
        imageMedium: backdrop_medium,
        imageLarge: backdrop_large,
      },
    },
    {
      name: "quote",
      settings: {
        image: influencer,
        quoteIconColor: "#984949",
      },
    },
    {
      name: "hero",
      settings: {
        image: heroImage,
        imageMedium: heroImageMedium,
        imageLarge: heroImageLarge,
      },
    },
    {
      name: "shop-below",
      settings: {
        bgColor: "#D38989",
      },
    },
    {
      name: "products",
      settings: {},
    },
    {
      name: "footer",
      settings: {
        bgColor: "#984949",
        logo: footerLogo,
      },
    },
  ],
};

export const secondary = {
  store: {
    storefrontAccessToken: "538fe6d6fd9123f61bd9a20481fee32c",
    store: "influencesciences.myshopify.com",
  },
  settingsGeneral: {
    productBuyBgColor: "#DCB7D1",
    productBuyTextColor: "#FFFFFF",
    brand: "Vitality",
    brandLink: "https://google.com",
    influencer: "Julie Turner",
    influencerLink: "https://yahoo.com",
    titleInfluence: "Julie Turner",
    titleBrand: "Vitality",
    quoteContent:
      "Vitality has some of the best quality accessories for the price. The collection is so diverse I can always find something for every occassion.",
    quoteAuthor: "Julie Turner",
    discount: {
      amount: {
        amount: "35.00",
        currency: "USD",
      },
      minimum: {
        amount: "50.00",
        currency: "USD",
      },
    },
    footerLinks: [
      { title: "Julie Turner", link: "http://google.com" },
      { title: "@julieturner", link: "http://yahoo.com" },
      { title: "julieturner.com", link: "http://msn.com" },
    ],
    footerLinksSecondary: [
      { title: "Vitality", link: "http://google.com" },
      { title: "vitalityaccessories.com", link: "http://yahoo.com" },
      { title: "@vitalityaccessories", link: "http://msn.com" },
    ],
  },
  sections: [
    {
      name: "main-header",
      settings: {
        brandImage: brandSecondary,
        influencerImage: influencerSecondary,
        dark: true,
      },
    },
    {
      name: "app-header-secondary",
      settings: {
        titleBgColor: "#FEF2B5",
        shopCtaColor: "#DCB7D1",
        image: backdropSecondary,
      },
    },
    {
      name: "quote-secondary",
      settings: {
        image: influencerSecondary,
        bgColor: "#F1E2ED",
      },
    },
    {
      name: "hero",
      settings: {
        image: heroImage2,
        imageMedium: heroImage2Medium,
        imageLarge: heroImage2Large,
      },
    },
    {
      name: "shop-below",
      settings: {
        bgColor: "#FEF2B5",
      },
    },
    {
      name: "products",
      settings: {},
    },
    {
      name: "footer",
      settings: {
        bgColor: "#DCB7D1",
        logo: footerLogo,
      },
    },
  ],
};

export const tertiary = {
  store: {
    storefrontAccessToken: "538fe6d6fd9123f61bd9a20481fee32c",
    store: "influencesciences.myshopify.com",
  },
  settingsGeneral: {
    productBuyBgColor: "#2B426F",
    productBuyTextColor: "#FFF",
    brand: "Alexander Apparel",
    brandLink: "https://google.com",
    influencer: "Wilson Garrett",
    influencerLink: "https://yahoo.com",
    titleInfluence: "Wilson Garrett",
    titleBrand: "Alexander Apparel",
    quoteContent:
      "Alexander Apparel has the perfect mix of casual basics and high-end fashion. Comfortable, simple and stylish.",
    quoteAuthor: "Wilson Garrett",
    discount: { percentage: "20" },
    footerLinks: [
      { title: "Wilson Garrett", link: "http://google.com" },
      { title: "@wilsongarrett", link: "http://yahoo.com" },
      { title: "wilsongarrett.com", link: "http://msn.com" },
    ],
    footerLinksSecondary: [
      { title: "Alexander Apparel", link: "http://google.com" },
      { title: "alexanderapparel.com", link: "http://yahoo.com" },
      { title: "@alexanderapparel", link: "http://msn.com" },
    ],
  },
  sections: [
    {
      name: "main-header",
      settings: {
        brandImage: brandTertiary,
        influencerImage: influencerTertiary,
        dark: false,
      },
    },
    {
      name: "app-header-tertiary",
      settings: {
        shopCtaColor: "#2B426F",
        image: backdropTertiary,
        brandImage: brandTertiary,
        influencerImage: influencerTertiary,
      },
    },
    {
      name: "quote-tertiary",
      settings: {
        image: influencerTertiary,
        bgColor: "#2B426F",
        textColor: "#FFF",
      },
    },
    {
      name: "hero",
      settings: {
        image: heroImage3,
        imageMedium: heroImage3Medium,
        imageLarge: heroImage3Large,
        spaceBelow: true,
      },
    },
    {
      name: "products",
      settings: {
        direction: "column",
      },
    },
    {
      name: "footer",
      settings: {
        bgColor: "#2B426F",
        logo: footerLogo,
      },
    },
  ],
};

export const quaternary = {
  store: {
    storefrontAccessToken: "538fe6d6fd9123f61bd9a20481fee32c",
    store: "influencesciences.myshopify.com",
  },
  settingsGeneral: {
    productBuyBgColor: "#B4C389",
    productBuyTextColor: "#FFF",
    brand: "Lux Skincare",
    brandLink: "https://google.com",
    influencer: "Ella Curtis",
    influencerLink: "https://yahoo.com",
    titleInfluence: "Ella Curtis",
    titleBrand: "Lux Skincare",
    quoteContent:
      "Lux Skincare so perfectly fits into both my skincare routine and my healthy lifestyle. Their products are packed with nutrients and I know I am using clean products.",
    quoteAuthor: "Ella Curtis",
    discount: {
      amount: {
        amount: "10.00",
        currency: "USD",
      },
      minimum: {
        amount: "100.00",
        currency: "USD",
      },
    },
    footerLinks: [
      { title: "Ella Curtis", link: "http://google.com" },
      { title: "@ellacurtis", link: "http://yahoo.com" },
      { title: "ellacurtis.com", link: "http://msn.com" },
    ],
    footerLinksSecondary: [
      { title: "Lux Skincare", link: "http://google.com" },
      { title: "luxskincare.com", link: "http://yahoo.com" },
      { title: "@luxskincare", link: "http://msn.com" },
    ],
  },
  sections: [
    {
      name: "main-header",
      settings: {
        brandImage: brandQuaternary,
        influencerImage: influencerQuaternary,
        dark: true,
      },
    },
    {
      name: "app-header-quaternary",
      settings: {
        shopCtaColor: "#B4C389",
        image1: appHeaderQuaternary1,
        image2: appHeaderQuaternary2,
        image3: appHeaderQuaternary3,
      },
    },
    {
      name: "quote-quaternary",
      settings: {
        image: influencerQuaternary,
        bgColor: "#B4C389",
      },
    },
    {
      name: "shop-below-secondary",
      settings: {},
    },
    {
      name: "products",
      settings: {
        direction: "row",
        total: 4,
      },
    },
    {
      name: "hero",
      settings: {
        image: heroImage4,
        imageMedium: heroImage4Medium,
        imageLarge: heroImage4Large,
        spaceBelow: true,
      },
    },
    {
      name: "products",
      settings: {
        direction: "row",
        total: 4,
      },
    },
    {
      name: "footer",
      settings: {
        bgColor: "#B4C389",
        logo: footerLogo,
      },
    },
  ],
};
