import React, { Component } from 'react';
import Scrollchor from 'react-scrollchor';

class Section extends Component {
  render() {
    return (
      <div className="header-secondary">
        <div className="header-secondary__content">
          <div className="header-secondary__title">
            <div className="header-secondary__title-main">
              <div className="header-secondary__title-content-wrapper">
                <h2 className="header-secondary__title-content">
                  {this.props.settingsGeneral.titleInfluence}&nbsp;&
                  <div className="header-secondary__title-content-highlight" style={{ backgroundColor: this.props.settings.titleBgColor }}></div>
                </h2>
              </div>
              <div className="header-secondary__title-content-wrapper">
                <h2 className="header-secondary__title-content">
                  {this.props.settingsGeneral.titleBrand}
                  <div className="header-secondary__title-content-highlight" style={{ backgroundColor: this.props.settings.titleBgColor }}></div>
                </h2>
              </div>
            </div>
            <br />
            <Scrollchor
              to="show-below"
              className="header-secondary__title-cta"
              style={{ backgroundColor: this.props.settings.shopCtaColor }}
            >
              Shop the collection
            </Scrollchor>
          </div>
          <div className="header-secondary__image">
            <img src={this.props.settings.image} alt="Header" />
          </div>
        </div>
      </div>
    );
  }
}

export default Section;