import React, { Component } from 'react';

class QuotesTertiary extends Component {
  render() {
    return (
      <div
        className="quotes-tertiary"
        style={{
          backgroundColor: this.props.settings.bgColor,
          color: this.props.settings.textColor,
        }}
      >
        <div className="quotes-tertiary__picture">
          <img className="quotes-tertiary__picture-content" src={this.props.settings.image} alt="" />
        </div>
        <div className="quotes-tertiary__main">
          <div className="quotes-tertiary__content">
            "{this.props.settingsGeneral.quoteContent}"
          </div>
          <div className="quotes-tertiary__author">
            — {this.props.settingsGeneral.quoteAuthor}
          </div>
        </div>
      </div>
    );
  }
}

export default QuotesTertiary;
